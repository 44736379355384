// Loading Constants
export const START_POST_LOADING = "START_POST_LOADING";
export const END_POST_LOADING = "END_POST_LOADING";
export const START_USER_PROFILE_LOADING = "START_USER_PROFILE_LOADING";
export const END_USER_PROFILE_LOADING = "END_USER_PROFILE_LOADING";
export const START_PROFILE_LOADING = "START_PROFILE_LOADING";
export const END_PROFILE_LOADING = "END_PROFILE_LOADING";
export const START_PROFILE_FOLLOWING_LIST_LOADING =
  "START_PROFILE_FOLLOWING_LIST_LOADING";
export const END_PROFILE_FOLLOWING_LIST_LOADING =
  "END_PROFILE_FOLLOWING_LIST_LOADING";
export const START_PROFILE_FOLLOWERS_LIST_LOADING =
  "START_PROFILE_FOLLOWERS_LIST_LOADING";
export const END_PROFILE_FOLLOWERS_LIST_LOADING =
  "END_PROFILE_FOLLOWERS_LIST_LOADING";
export const START_FOllOW_UNFOLLOW_LOADING = "START_FOllOW_UNFOLLOW_LOADING";
export const END_FOllOW_UNFOLLOW_LOADING = " END_FOllOW_UNFOLLOW_LOADING";
export const START_FETCH_USERS_BY_SEARCH_LOADING =
  "START_FETCH_USERS_BY_SEARCH_LOADING";
export const END_FETCH_USERS_BY_SEARCH_LOADING =
  "END_FETCH_USERS_BY_SEARCH_LOADING";
export const START_FETCH_COMMENT_BY_POST_ID = "START_FETCH_COMMENT_BY_POST_ID";
export const END_FETCH_COMMENT_BY_POST_ID = "END_FETCH_COMMENT_BY_POST_ID";
export const START_FETCH_REPLIES_BY_COMMENT = "START_FETCH_REPLIES_BY_COMMENT";
export const FETCH_REPLIES_BY_COMMENT = "FETCH_REPLIES_BY_COMMENT";
export const END_FETCH_REPLIES_BY_COMMENT = "END_FETCH_REPLIES_BY_COMMENT";

// Auth CRUD Constants
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const INITIATE_RESET = "INITIATE_RESET";
export const RE_INITIATE_RESET = "RE_INITIATE_RESET";
export const FAILED_INITIATE_RESET = "FAILED_INITIATE_RESET";

// Post CRUD Constants
export const FETCH_ALL_POSTS = "FETCH_ALL_POSTS";
export const FETCH_POSTS_BY_FOLLOWING = "FETCH_POSTS_BY_FOLLOWING";
export const FETCH_POSTS_BY_FOLLOWING_UPDATED_SAVE =
  "FETCH_POSTS_BY_FOLLOWING_UPDATED_SAVE";
export const FETCH_POSTS_BY_FOLLOWING_UPDATED_UNSAVE =
  "FETCH_POSTS_BY_FOLLOWING_UPDATED_UNSAVE";
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH";
export const FETCH_POST_BY_ID = "FETCH_POST_BY_ID";
export const FETCH_COMMENT_BY_POST_ID = "FETCH_COMMENT_BY_POST_ID";
export const FETCH_SAVED_POST_BY_USER = "FETCH_SAVED_POST_BY_USER";
export const CREATE_POST = "CREATE_POST";
export const UPDATE_POST = "UPDATE_POST";
export const LIKE_POST = "LIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";
export const LIKE_UNLIKE_COMMENT_REPLY = "LIKE_UNLIKE_COMMENT_REPLY";
export const COMMENT_POST_WITH_USER_DETAILS = "COMMENT_POST_WITH_USER_DETAILS";
export const SUBMIT_REPLY_TO_COMMENT_OR_REPLY =
  "SUBMIT_REPLY_TO_COMMENT_OR_REPLY";
export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_COMMENT = "DELETE_POST_COMMENT";
export const DELETE_POST_REPLY = "DELETE_POST_REPLY";
export const DELETE_USER_POST = "DELETE_USER_POST";
export const FETCH_POST_BY_USER = "FETCH_POST_BY_USER";
export const UPDATE_PROFILE_DETAILS = "UPDATE_PROFILE_DETAILS";
// export const UPDATE_PROFILE_PASSWORD = "UPDATE_PROFILE_PASSWORD";
export const UPDATE_PROFILE_PICTURES = "UPDATE_PROFILE_PICTURES";

// Profile CRUD Constants
export const GET_FOLLOWING_AND_FOLLOWERS_COUNT =
  "GET_FOLLOWING_AND_FOLLOWERS_COUNT";
export const GET_FOLLOWING_PROFILE_DETAILS = "GET_FOLLOWING_PROFILE_DETAILS";
export const GET_FOLLOWERS_PROFILE_DETAILS = "GET_FOLLOWERS_PROFILE_DETAILS";
export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS";
export const GET_USER_PROFILE_DETAILS = "GET_USER_PROFILE_DETAILS";

// Request CRUD Constants
export const SEND_FOllOW_REQUEST = "SEND_FOllOW_REQUEST";
export const SEND_UNFOllOW_REQUEST = "SEND_UNFOllOW_REQUEST";
export const REMOVE_FOLLOWER = "REMOVE_FOLLOWER";

// Chat & Message Constants
export const FETCH_USERS_BY_SEARCH = "FETCH_USERS_BY_SEARCH";

// Notification
export const START_NOTIFICATION_LIST_LOADING =
  "START_NOTIFICATION_LIST_LOADING";
export const STOP_NOTIFICATION_LIST_LOADING = "STOP_NOTIFICATION_LIST_LOADING";
export const FETCH_NOTIFICATION_COUNT = "FETCH_NOTIFICATION_COUNT";
export const FETCH_NOTIFICATIONS_LIST = "FETCH_NOTIFICATIONS_LIST";

// State for Post's Comment & Reply
export const SET_STATE_FOR_COMMENT_REPLY = "SET_STATE_FOR_COMMENT_REPLY";
export const SET_COMMENT_REPLY_DETAILS = "SET_COMMENT_REPLY_DETAILS";
