import React from "react";

import "./forgortPassword.css";

import ForgotPasswordComp from "../../components/ForgotPassword/ForgotPassword";

const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswordComp />
    </>
  );
};

export default ForgotPassword;
